<template>
  

<div  class="card  shadow border border-white bg-white h-100 pb-3 overflow-auto">
        <div class="row pb-1 border-bottom border-info m-3">


                <h4 class="font-weight-lighter text-info"> <span class="fa fa-folder-open"></span> Suivi Des Paiements Garages</h4> 
            
        </div>














<div class="h-100 m-2">
  

            <div class="col-12 mb-3 ">

              <div class="card shadow h-100 pr-3 pl-3">




  <b-input-group prepend="Garages "  class="mt-3">

            <select class="form-control" id="curentgarg" v-model="curentgarg">
                <option v-for="item in garages" :key="item.id" v-bind:value="item.id">   
                {{ item.nom }}
                </option>

           </select>


    <b-input-group-append>
      <b-button pill class="ml-2" variant="outline-info"  @click="click_RechrchFourns(0)">
          <span class="fa fa-search"></span>
          Stituation Courente
        </b-button>


      <b-button pill class="ml-2" variant="outline-primary"  @click="click_RechrchFourns(1)">
          <span class="fa fa-search"></span>
          Situation Réglée
        </b-button>

        <b-button v-if="curentgarg && situCourent==true && total_Solde==0" pill class="ml-2" variant="outline-success"  @click="click_regler_situation">
          <span class="fa fa-check"></span>
          Régler La Situation
        </b-button>

    </b-input-group-append>
  </b-input-group>






                                     

                            <div class="row mt-3">
                                        <div class="col">
                                                                <div class=" d-flex justify-content-center pt-1 text-secondary">
                                                                        <h5 class="font-weight-light ">Total Bons : {{total_maints}} </h5>
                                                                </div>
                                        </div>

                                        <div class="col">
                                                                <div class=" d-flex justify-content-center pt-1 text-secondary">
                                                                        <h5 class="font-weight-light ">Total Paiements : {{total_paiement}} </h5>
                                                                </div>
                                        </div>
                                        <div class="col">
                                                                <div class=" d-flex justify-content-center pt-1 text-primary">
                                                                        <h5 class="font-weight-light ">Solde : {{total_Solde}} </h5>
                                                                </div>
                                        </div>

                            </div>
              </div>
            </div>





            <div class="col-12 mb-3 ">

              <div class="card h-100 py-1">
                <div class="card-body p-1">




                <div class="float-left pt-1 text-secondary">
                    <h5 class="font-weight-normal ml-2">Paiements</h5>
                </div>



                <b-button v-if="curentgarg && situCourent==true"   class="ml-2 mt-0  mb-2 float-left text-success" variant="link" @click="add_Paiem_click($event.target)">
                            <i class="fa fa-plus-square"></i>

                </b-button>

                            <b-table 
                            small 
                            responsive
                            sort-icon-left
                            sticky-header
                            :tbody-tr-class="rowClass"
                            :fields="fields" 
                            :items="itemslist"
                            >
                                <!-- A custom formatted header cell for field 'name' -->
                                <template #head(date)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(montant)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(mode_oper)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>
                                <template #head(infos_oper)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(date_ech)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(etat)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>
                                <template #head(regler)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(user)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>
                                <template #head(description)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>


                                <template #cell(actions)="row">
                                        <b-button key="supp" v-if="row.item.etat==0 && row.item.regler==0" pill   class="mr-1 float-right text-danger" variant="link" @click="del_click(row.item)">
                                                    <i class="fa fa-trash-alt"></i>
                                            
                                        </b-button>

                                        <b-button key="edit" v-if="row.item.etat==0 && row.item.regler==0" pill  class="mr-1 float-right text-primary" variant="link" @click="edit_click(row.item, row.index, $event.target)">
                                                <i class="fa fa-pencil-alt"></i>
                                        
                                        </b-button>

                                        <b-button key="val" v-if="row.item.etat==0 && row.item.regler==0" pill   class="mr-1 float-right text-success" variant="link" @click="validerPaiement(row.item, 1)">
                                                    <i class="fa fa-check-square"></i>
                                            
                                        </b-button>
                                        <b-button key="deval" v-if="row.item.etat==1 && row.item.regler==0" pill  class="mr-1 float-right text-danger" variant="link" @click="validerPaiement(row.item, 0)">
                                                <i class="fa fa-check-square"></i>
                                        
                                        </b-button>
                                    </template>
                            
                            </b-table>
          


                </div>
              </div>
            </div>



            <div class="row m-0">
            <div class="col">
              <div class="card  h-100 py-1">
                <div class="card-body p-0">

                <div class="float-left pt-1 text-secondary">
                    <h5 class="font-weight-normal ml-2"> Maintenances</h5>
                </div>



                <b-table 
                            small 
                            responsive
                            sort-icon-left
                            sticky-header


                            :fields="fieldsLiv" 
                            :items="itemlistmaintenc"
                            >


                                <!-- A custom formatted header cell for field 'name'  -->
                                <template #head(matricul)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(prix)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(date)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(description)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #cell(actions)="row">

                                        <b-button pill v-if="row.item.etat==0"  class="mr-2 float-right text-primary" variant="link" @click="pay_bon_click(row.item, row.index, $event.target)">
                                                <i class="fa fa-coins"></i>
                                        
                                        </b-button>
                                    </template>
                            
                </b-table>

                </div>
              </div>
            </div>

</div>



  
</div> 


    <!-- le modal -->
    <b-modal id="banqModal" title="Paiement" size="lg" hide-footer>
        <form @submit.prevent="handleSubmit">




            <div class="row">

                <div class="form-group col">
                <label for="date_exp" class="mr-sm-2">Date :</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_exp"
                    placeholder="date"
                    v-model="itemForm.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>


            <div class="form-group col">
                <label for="date_ech" class="mr-sm-2">Date Echéance:</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_ech"
                    placeholder="Date Echéance"
                    v-model="itemForm.date_ech"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>

            </div>

            <div class="form-group">
                <label for="montant">Montant (DH) :</label>
                <input type="text" v-model="itemForm.montant" id="montant" name="montant" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.montant.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.montant.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.montant.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>

            <div class="row">

                        <div class="form-group col">
                                        <label for="mode">Mode de paiement :</label>
                                        <select class="form-control" id="mode" v-model="itemForm.mode_oper"
                                         :class="{ 'is-invalid': isSubmitted && $v.itemForm.mode_oper.$error }">
                                        <option v-for="item in modes" :key="item.id" v-bind:value="item.mode">   
                                            {{ item.mode }}
                                        </option>
                                        </select>
                                    <div v-if="isSubmitted && !$v.itemForm.mode_oper.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        </div>
                        <div class="form-group col">
                            <label for="infos">Infos de paiement :</label>
                            <input type="text" v-model="itemForm.infos_oper" id="infos" name="infos" class="form-control" />

                        </div>
            </div>


            <div v-if="!ModalEdit" class="row">

                            <div class="form-group col">
                            <div class="custom-control custom-switch">
                            <input type="checkbox"
                            class="custom-control-input" 
                            id="etat"
                                    v-model="itemForm.etat"
                                    name="etat"
                                    value=true
                                    unchecked-value=false>

                            <label class="custom-control-label" for="etat">Payé :</label>
                            </div>
                            </div>

                        <div v-if="itemForm.etat" class="form-group col">
                                        <label for="bank_compte_id">Compte :</label>
                                        <select class="form-control" id="bank_compte_id" v-model="itemForm.bank_compte_id"
                                        :class="{ 'is-invalid': isSubmitted && $v.itemForm.bank_compte_id.$error }">
                                        <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                            {{ item.nom }}
                                        </option>
                                        </select>

                            <div v-if="isSubmitted && !$v.itemForm.bank_compte_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                        </div>

                     </div>




            <div class="form-group">
            <label for="Description">Description :</label>
            <textarea name="description" id="description"  rows="2" class="form-control" v-model="itemForm.description"></textarea>

            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>

        </form>
    </b-modal>


    <!-- le modal -->
    <b-modal id="comptModal" title="Sélectionnez Un compte" hide-footer>
        <form @submit.prevent="handleSubmitValidPayem">



                <div class="form-group col">
                            <label for="bank_compte_idval">Compte :</label>
                            <select class="form-control" id="bank_compte_idval" v-model="itemFormValid.bank_compte_id">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block mt-4">Valider / Dévalider</button>
            </div>



        </form>
    </b-modal>
 

    









      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";

export default {

computed: {
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
      total_paiement: function() {

return this.itemslist.filter(({etat}) => etat === 1).reduce((a, b) => +a + +b.montant, 0);
    },
      total_maints: function() {
      return this.itemlistmaintenc.reduce((a, b) => +a + +b.prix, 0);
    },


    total_Solde: function() {
      return this.total_maints - this.total_paiement;
    },

    },
    mounted(){

      this.fetchModesFourniss()

    },
    data () {
		return {
            situCourent:false,
            curentgarg:'',
            garages:[],
            modes:[],
            comptes:[],
            itemslist: [],
            itemForm: {
                    id:'',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    montant:'',
                    mode_oper:'',
                    infos_oper:'',
                    date_ech:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    description:'',
                    etat:0,
                    regler:0,
                    user:'user',
                    garage_id:'',
                    bank_compte_id:''},
            fields: [
                {key: 'date', label: 'Date'},
                {key: 'montant', label: 'Montant'},
                {key: 'mode_oper', label: 'Mode'},
                {key: 'infos_oper', label: 'Info'},
                {key: 'date_ech', label: 'Date Ech'},
                {key: 'etat', label: 'Payé',
                 formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }},
                {key: 'regler', label: 'Réglé',
                                 formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }},
                {key: 'user', label: 'utilisateur'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],

            //livraison:
            itemlistmaintenc: [],
            fieldsLiv: [
                {key: 'matricul', label: 'Matricul'},
                {key: 'prix', label: 'Prix'},
                {key: 'date', label: 'Date'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],

            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false,
            itemFormValid: {
                    id:'',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    etat:0,
                    user:'user',
                    bank_compte_id:''},



		}
    },
    validations: {
            itemForm: {
                date: {
                    required
                },
                montant: {
                    required,
                    decimal
                },
                mode_oper: {
                    required
                },
                date_ech: {
                    required
                },
                garage_id: {
                    required
                },
                bank_compte_id: {
                    required
                }

            }

    },
 methods:{
     click_regler_situation()
     {
                this.$confirm("Voulez-vous vraiment régler la situation courente de ce garage ??")
                    .then(() => {

                                            //regler la situation
                                            this.isLoading=true;
                                            this.$http.get('/situtionReglGarag/'+this.curentgarg)
                                            .then((result) => {

                                            console.log(result.data)
                                            this.flashMessage.show({
                                            status: 'success',
                                            title: 'Données modifiées',
                                            message: 'Les données sont modifiées correctement'
                                            })

                                            //recharger les données:
                                            this.click_RechrchFourns(0)

                                            })
                                            .catch(error=> {
                                                        console.log(error)
                                                        this.isLoading=false;
                                                        this.flashMessage.show({
                                                        status: 'error',
                                                        title: 'Erreur de Chargement',
                                                        message: 'Impossible charger les données.. Actualisez et Réessayez'
                                                        })
                                            });

                    })
                    .catch(error=> {
                    console.log(error)

                });
     },
     validerPaiement(item,val)
     {
            this.itemFormValid.id=item.id
            this.itemFormValid.etat=val
            this.itemFormValid.user=this.user_name
            this.itemFormValid.bank_compte_id=''
            this.itemFormValid.date=new Date().toJSON().slice(0,10).replace(/-/g,'-'),


            this.$root.$emit('bv::show::modal', 'comptModal')
     },
     handleSubmitValidPayem() {


                if (this.itemFormValid.bank_compte_id) 
                {
                                            this.isLoading=true;

                                this.$http.post('/validerPayemGarag',{
                                id:this.itemFormValid.id,
                                etat:this.itemFormValid.etat,
                                user:this.itemFormValid.user,
                                bank_compte_id:this.itemFormValid.bank_compte_id,
                                date:this.itemForm.date


                                    })
                                    .then(response => {

                                        this.isLoading=false;
                                        console.log(response.data)
                                        
                                        this.$bvModal.hide('banqModal')

                                        let index = this.itemslist.findIndex(item => item.id === this.itemFormValid.id)
                                        this.itemslist.splice(index, 1,response.data.data)

                                        this.$bvModal.hide('comptModal')

                                        //afficher message
                                        this.flashMessage.show({
                                        status: 'success',
                                        title: 'Données sauvegardées',
                                        message: 'Les données sont sauvegardées correctement '
                                    })


                                    })
                                    .catch(error=> {console.log(error)
                                    this.isLoading=false;


                                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur De Sauvegarde',
                                    message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                    })
                                    });
                }
                else 
                {
                this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un compte !!',
                })
                }

     },  
     fetchModesFourniss: function () {

                    //chager les garages:
                    this.isLoading=true;
                    this.$http.get('/garages')
                    .then((result) => {
                                this.garages = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

                    //chager les modes:
                    this.isLoading=true;
                    this.$http.get('/bankModes')
                    .then((result) => {
                                this.modes = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

                    //chager les modes:
                    this.isLoading=true;
                    this.$http.get('/bankComptes')
                    .then((result) => {
                                this.comptes = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

     },
     handleSubmit() {

            
                //USER HANDLE
                this.itemForm.user= this.user_name

                if(this.itemForm.etat==0)
                {
                    this.itemForm.bank_compte_id=0
                    
                }

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.itemslisttore()
                            }   

                }

     },  
     itemslisttore(){
                        this.isLoading=true;

            this.$http.post('/paiemGarages',{
            date:this.itemForm.date,
            montant:this.itemForm.montant,
            mode_oper:this.itemForm.mode_oper,
            infos_oper:this.itemForm.infos_oper,
            date_ech:this.itemForm.date_ech,
            description:this.itemForm.description,
            etat:this.itemForm.etat,
            regler:this.itemForm.regler,
            user:this.itemForm.user,
            garage_id:this.itemForm.garage_id,
            bank_compte_id:this.itemForm.bank_compte_id

                })
                .then(response => {

                    this.isLoading=false;
                    this.itemslist.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

    },

     add_Paiem_click(button) {

            this.itemForm.date=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            this.itemForm.montant=''
            this.itemForm.mode_oper=''
            this.itemForm.infos_oper=''
            this.itemForm.date_ech=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            this.itemForm.description=''
            this.itemForm.etat=0
            this.itemForm.regler=0
            this.itemForm.garage_id = this.curentgarg

            this.ModalEdit=false;
            this.ModalType='Ajouter'

            this.$root.$emit('bv::show::modal', 'banqModal', button)
        },
        edit_click(item, index, button) {

                    this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                    .then(() => {

                this.itemForm.id=item.id
                this.itemForm.date=item.date
                this.itemForm.montant=item.montant
                this.itemForm.mode_oper=item.mode_oper
                this.itemForm.infos_oper=item.infos_oper
                this.itemForm.date_ech=item.date_ech
                this.itemForm.description=item.description
                this.itemForm.etat=item.etat
                this.itemForm.regler=item.regler
                this.itemForm.user=item.user
                this.itemForm.garage_id=item.garage_id





                    this.ModalEdit=true;
                    this.ModalType='Modifier'

                    this.$root.$emit('bv::show::modal', 'banqModal', button)
                    })
                    .catch(error=> {
                    console.log(error)

                    });


        },
        banqueEdit(){

                this.isLoading=true;

                this.$http.patch('/paiemGarages/'+this.itemForm.id,{
                date:this.itemForm.date,
                montant:this.itemForm.montant,
                mode_oper:this.itemForm.mode_oper,
                infos_oper:this.itemForm.infos_oper,
                date_ech:this.itemForm.date_ech,
                description:this.itemForm.description,
                etat:this.itemForm.etat,
                regler:this.itemForm.regler,
                user:this.itemForm.user,
                garage_id:this.itemForm.garage_id

                    })
                    .then(response => {

                        this.isLoading=false;
                        console.log(response.data)
                        this.$bvModal.hide('banqModal')

                        let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                        this.itemslist.splice(index, 1,response.data.data)
                        
                        this.flashMessage.show({
                        status: 'success',
                        title: 'Données modifiées',
                        message: 'Les données sont modifiées correctement'
                        })


                        })
                        .catch(error=> {console.log(error)
                        this.isLoading=false;


                        this.flashMessage.show({
                        status: 'error',
                        title: 'Erreur De Modification',
                        message: 'Impossible  de modifier les données .. Réessayez !!'
                    })
                    });

            },
        del_click(item) {

                    this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                    .then(() => {
                    this.itemForm.id=item.id

                                        this.PaiementDell()
                                    })
                                    .catch(error=> {
                                    console.log(error)

                                        });




        },
        PaiementDell(){

                this.isLoading=true;

                this.$http.delete('/paiemGarages/'+this.itemForm.id)
                    .then(response => {
                    console.log(response.data)
                        this.isLoading=false;



                        
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données Supprimées',
                    message: 'Les données sont supprimées correctement'
                    })


                        let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                        this.itemslist.splice(index, 1)




                        })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Suppression',
                    message: 'Impossible  de supprimer les données .. Réessayez !!'
                    })
                    });

        },

        click_RechrchFourns(reg)
        {
                if(this.curentgarg)
                {
                                if(reg==0)
                                {
                                this.situCourent=true
                                }
                                else
                                {
                                this.situCourent=false
                                }
                                            
                                this.fetchListPaiem(reg)
                                this.fetchmaintenance(reg)
                               
                }
                else
                {
                                this.flashMessage.show({
                                status: 'info',
                                title: 'Sélectionnez un fournisseur !!',
                                })
                }

        },

//paiements :
        fetchListPaiem: function (reg) {

                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/paiementsGarageReglers/'+this.curentgarg+'/'+reg)
                    .then((result) => {

                                
                                this.itemslist = result.data.data
                                this.isLoading=false;


                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },

//livraison coins
        fetchmaintenance: function (reg) {
                    this.isLoading=true;
                    this.$http.get('/maintCamionRegler/'+this.curentgarg+'/'+reg)
                    .then((result) => {
                                this.itemlistmaintenc = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        pay_bon_click(item, index, button) {

            this.$confirm("Voulez-vous créer un paiement pour ce bon ??")
            .then(() => {



            this.itemForm.date=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            this.itemForm.montant=item.prix
            this.itemForm.mode_oper=''
            this.itemForm.infos_oper=''
            this.itemForm.date_ech=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            this.itemForm.description=item.description
            this.itemForm.etat=1
            this.itemForm.regler=0
            this.itemForm.garage_id = this.curentgarg


            this.ModalEdit=false;
            this.ModalType='Ajouter'


                    this.$root.$emit('bv::show::modal', 'banqModal', button)
                    })
                    .catch(error=> {
                    console.log(error)

                    });


        },


      rowClass(item, type) {
        if (!item || type !== 'row') return
        if (item.etat === 1) return 'table-success'
      }
},
components: {
              Loading      
}


}
</script>

<style>
.Pgheader {
  background-color: blue;
  opacity: 0.3;
}
.BgSearch {
  background-color:#BEE5EB;
  opacity: 0.9;
}

</style>