<template>
<div>
  
                        
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">   

                            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-book"></span> Operations Des Comptes </h4> 
                                
                                
                                <div class="col">
                                <b-input-group prepend="Compte " class="mt-0">

                                            <select class="form-control" id="curentCmpt" v-model="curentCmpt">
                                                <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                                </option>

                                                <option value=0>Tous</option>

                                        </select>


                                    <b-input-group-append>
                                    <b-button variant="info"  @click="fetchList">
                                        <span class="fa fa-search"></span>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>

                                </div>










                    <div class="col">
                                    <b-button pill size="sm"  class="mt-3 mb-3 mr-2 float-right" variant="success" @click="add_click()">
                                                <i class="fa fa-plus-square"></i>
                                        <span> (En/De)caissement</span> 
                                    </b-button>

                                    <b-button pill size="sm"  class="mt-3 mb-3 mr-2 float-right" variant="info" @click="exportDox">
                                        <i class="fa fa-print"></i>
                                        <span> impr.</span> 
                                    </b-button>
                    </div>
            </div>
       



        <b-table 
         id="main-table"
        :per-page="perPage"
        :current-page="currentPage"
        
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"

        >

        
        </b-table>
                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

        
      </div>



              <div class="card border-left-success shadow mt-3">
                <div class="card-body p-0">

                        <div class=" d-flex justify-content-center pt-1 text-secondary">
                                <h4 class="font-weight-normal p-2">TOTAL : {{total_compt}} DH</h4>
                        </div>

                </div>
              </div>


    <!-- le modal -->
    <b-modal id="banqModal" title="Encaissement / Decaissement"  size="lg" hide-footer>
           <form @submit.prevent="handleSubmit">




<div class="row">
    <b-form-group label="Type de l'opération" class="col form-group">
      <b-form-radio-group
        v-model="typeEncaisDecais"
        :options="options"
        name="typeEncaisDecais"
      ></b-form-radio-group>
    </b-form-group>


                <div class="form-group col">
                            <label for="bank_compte_id">Compte :</label>
                            <select class="form-control" id="bank_compte_id" v-model="itemForm.bank_compte_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.bank_compte_id.$error }">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

                <div v-if="isSubmitted && !$v.itemForm.bank_compte_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

            </div>
</div>

<div class="row">
            <div class="form-group col">
                <label for="date_exp" class="mr-sm-2">Date :</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_exp"
                    placeholder="date"
                    v-model="itemForm.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>


            <div class="form-group col">
                <label for="montant_oper">Montant :</label>
                <input type="text" v-model="itemForm.montant_oper" id="montant_oper" name="montant_oper" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.montant_oper.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.montant_oper.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.montant_oper.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>
</div>



<div class="row">
            <div class="form-group col">
                            <label for="mode_oper">Mode de l'operation :</label>
                            <select class="form-control" id="mode_oper" v-model="itemForm.mode_oper"
                             :class="{ 'is-invalid': isSubmitted && $v.itemForm.mode_oper.$error }">
                            <option v-for="item in modes" :key="item.id" v-bind:value="item.mode">   
                                {{ item.mode }}
                            </option>
                            </select>
                                    <div v-if="isSubmitted && !$v.itemForm.mode_oper.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

            </div>

            <div class="form-group col">
                <label for="infos_oper">Infos de l'operation :</label>
                <input type="text" v-model="itemForm.infos_oper" id="infos_oper" name="infos_oper" class="form-control" />

            </div>


</div>









            <div class="form-group">
            <label for="Description">Description</label>
            <textarea name="description" id="description"  rows="4" class="form-control" v-model="itemForm.description"></textarea>

            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>

        </form>

    </b-modal>


    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
      computed: {

      rows_pagin() {
        return this.itemslist.length
      },
    


        user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
      total_compt: function() {
          
      return Number(this.itemslist.reduce((a, b) => +a + +b.montant_oper, 0)).toFixed(2);
    },

    },
    mounted(){
        this.fetchList()
        this.fetchFormModeComptes()
    },
	data () {
		return {

                        perPage: 10,
            currentPage: 1,
            typeEncaisDecais:false,
                    options: [
          { text: 'Encaissement', value: false },
          { text: 'Decaissement', value: true },

        ],
            //fin check box
today:new Date().toJSON().slice(0,10).replace(/-/g,'-'),

            curentCmpt:0,
            itemslist: [],
            modes:[],
            comptes:[],

            itemForm: {
                    id:'',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    montant_oper:'',
                    infos_oper:'',
                    mode_oper:'',
                    description:'',
                    user:'user',
                    bank_compte_id:''},
            fields: [

                {key: 'date', label: 'Date', sortable: true},
                {key: 'montant_oper', label: 'Montant', sortable: true},
                {key: 'mode_oper', label: 'Mode', sortable: true},
                {key: 'infos_oper', label: 'Infos', sortable: true},
                {key: 'description', label: 'Description', sortable: true},
                {key: 'nom', label: 'Compte', sortable: true},
                {key: 'user', label: 'Utilisateur', sortable: true},

            ],
            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemForm: {
                date: {
                    required
                },
                montant_oper: {
                    required,
                    decimal
                },
                mode_oper: {
                    required
                },
                bank_compte_id: {
                    required
                }
            }
    },
    methods :{

        exportDox()
        {

       let comp = ""


        if(this.curentCmpt==0)
        {
        comp="Tous"
        }
        else
        {
        let mm=this.comptes.findIndex(item => item.id === this.curentCmpt)

        comp= this.comptes[mm].nom
        }



        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Compte",
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Date",
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Montant",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1700,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Mode",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1700,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Infos",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Description",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        new TableCell({
                            width: {
                                size: 1700,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Utilisateur",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })]
        this.itemslist.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.nom,
                                                    spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1600,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.date,
                                                    spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.montant_oper.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1700,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.mode_oper,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 1700,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.infos_oper,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.description,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 1700,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.user,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                    ],
                })

        datrw.push(
        r
        )
        });

                const doc = new Document();

        const table = new Table({

            rows:  datrw
        })



                doc.addSection({


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                                new Paragraph({
                                    children: [
                            new TextRun({
                                text: "تعاونية حليب الريش",
                                color: '636572',
                                bold: true,

                                })
                                    ],
                                border: {
                                bottom: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),
                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Operations Des Comptes",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),
                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),
                        
                        
                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "     Compte : "+comp+"         Date : "+this.today,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            })
                            ],

                            heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),


                        table,
                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Total : "+ this.total_compt.toString(),
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),
                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        })
                        
                        ]
                })







            Packer.toBlob(doc).then(blob => {

        saveAs(blob, "soldes_compts_"+comp+".docx");

            });

        },


       
        fetchFormModeComptes: function () {
                    this.isLoading=true;
                    this.$http.get('/bankModes')
                    .then((result) => {
                                this.modes = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
            //comptes :
                    this.isLoading=true;
                    this.$http.get('/bankComptes')
                    .then((result) => {
                                this.comptes = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
       

        itemslisttore(mont){
            this.isLoading=true;

            this.$http.post('/bankOpers',{
            date:this.itemForm.date,
            montant_oper:mont,
            infos_oper:this.itemForm.infos_oper,
            mode_oper:this.itemForm.mode_oper,
            description:this.itemForm.description,
            bank_compte_id:this.itemForm.bank_compte_id,
            user:this.itemForm.user

                })
                .then(response => {

                    this.isLoading=false;
                    this.itemslist.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },

        add_click() {



            this.itemForm.id=''
            this.itemForm.date=new Date().toJSON().slice(0,10).replace(/-/g,'-')
            this.itemForm.montant_oper=0
            this.itemForm.infos_oper=''
            this.itemForm.mode_oper=''
            this.itemForm.description=''
            this.itemForm.bank_compte_id=''


        this.ModalEdit=false;
        this.ModalType='Ajouter'

        this.$root.$emit('bv::show::modal', 'banqModal')
      },

        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/oprationComptAvecNom/'+this.curentCmpt)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        handleSubmit() {

                //USER HANDLE
                this.itemForm.user= this.user_name

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                    let mnt = this.itemForm.montant_oper

                    if(this.typeEncaisDecais)
                    {

                       mnt = mnt*-1

                       this.itemForm.description="Decaissement. "+this.itemForm.description
                    }


                            if(!this.ModalEdit)
                            {
                            this.itemForm.description="Encaissement. "+this.itemForm.description

                                this.itemslisttore(mnt)
                            }   

                }

            }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>