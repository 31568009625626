<template>
<div>
  
                        

      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">   

                            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-hotel"></span> Gestion Des Banques    </h4> 
            

                            <div class="col">
                                            <b-button pill size="sm"  class="m-3 float-right" variant="success" @click="add_click($event.target)">
                                                        <i class="fa fa-plus-square"></i>
                                                <span> Ajouter</span> 
                                            </b-button>

                                            <b-button pill size="sm"  class="m-3 float-right" variant="info" @click="fetchBanqs">
                                                <i class="fa fa-sync-alt"></i>
                                                <span> Actualiser</span> 
                                            </b-button>
                            </div>
            </div>
       



        <b-table 
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="banques"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-primary" @click="edit_click(row.item, row.index, $event.target)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>
                </template>
        
        </b-table>
      </div>


    <!-- le modal -->
    <b-modal id="banqModal" :title="banqForm.nom"  hide-footer>
           <form @submit.prevent="handleSubmit">
            <div class="form-group">
                <label for="name">Nom Banque</label>
                <input type="text" v-model="banqForm.nom" id="name" name="name" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.banqForm.nom.$error }" />
                <div v-if="isSubmitted && !$v.banqForm.nom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>
            <div class="form-group">
            <label for="Description">Description</label>
            <textarea name="description" id="description"  rows="4" class="form-control" v-model="banqForm.description"></textarea>

            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>


        </form>
    </b-modal>


    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";

export default {
      computed: {
    tokenX() {
      return this.$store.getters.get_token;
    }
      },
    mounted(){
        this.fetchBanqs()
    },
	data () {
		return {
            banques: [],
            banqForm: {
                    id:'',
                    nom:'',
                    description:''},
            fields: [
				{key: 'nom', label: 'Nom', sortable: true},
                {key: 'description', label: 'Description', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            banqForm: {
                nom: {
                    required
                }
            }
    },
    methods :{


        banquestore(){
                        this.isLoading=true;

            this.$http.post('/banques',{
            nom:this.banqForm.nom,
            description:this.banqForm.description,
                })
                .then(response => {

                    this.isLoading=false;
                    this.banques.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        banqueEdit(){

            this.isLoading=true;

            this.$http.patch('/banques/'+this.banqForm.id,{
            nom:this.banqForm.nom,
            description:this.banqForm.description

                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data)
                    this.$bvModal.hide('banqModal')

                    let index = this.banques.findIndex(item => item.id === this.banqForm.id)
                    this.banques.splice(index, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        banqueDell(){

            this.isLoading=true;

            this.$http.delete('/banques/'+this.banqForm.id)
                .then(response => {
                console.log(response.data)
                    this.isLoading=false;



                    
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.banques.findIndex(item => item.id === this.banqForm.id)
                    this.banques.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        add_click(button) {
        this.banqForm.id=''
        this.banqForm.nom=''
        this.banqForm.description=''
        this.ModalEdit=false;
        this.ModalType='Ajouter'

        this.$root.$emit('bv::show::modal', 'banqModal', button)
      },
        edit_click(item, index, button) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                 .then(() => {
                this.banqForm.id=item.id
                this.banqForm.nom=item.nom
                this.banqForm.description=item.description

                this.ModalEdit=true;
                this.ModalType='Modifier'

                this.$root.$emit('bv::show::modal', 'banqModal', button)
                })
                .catch(error=> {
                console.log(error)

                });


      },
        del_click(item) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {
                this.banqForm.id=item.id
                this.banqForm.nom=item.nom
                this.banqForm.description=item.description
                                    this.banqueDell()
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




      },
        fetchBanqs: function () {


                    this.isLoading=true;
                    this.$http.get('/banques')
                    .then((result) => {
                                this.banques = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        handleSubmit() {
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.banquestore()
                            }   

                }

            }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>